.popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
  background-color: black;
  color: white;
  min-height: 150px;
  min-width: 150px;
  max-width: 240px;
  border: 1px solid white;
  border-radius: 10px;
  padding: 8px;

  &__header {
    border-bottom: 1px solid white;
    margin: 0 0 16px;
    text-align: center;
  }

  &__footer {
    margin-top: 16px;
  }

  &__btn {
    width: 100% !important;
    margin: 0;
  }
}