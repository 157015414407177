.main-header {
  height: 50px;
  width: 100%;
  margin: 0;
  padding-top: 5px;
  background: rgba(32, 32, 32, 0.6);
  text-align: center;
  font-size: 2.0em;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;

  img {
    height: 30px;
    position: absolute;
    top: 10px;
  }

  &__menu {
    width: 30px;
    left: 16px;
    cursor: pointer;
  }

  &__logo {
    width: auto;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    cursor: default;

    &.lite {
      height: 43px;
      top: 5px;
    }
  }

  &__version {
     font-size: 0.6em;
     position: absolute;
     top: 15px;
     right: 16px;
   }
}
