@import './../../breakpoints.scss';

.main-layout {
  &__body {
    padding-top: 50px !important;
    transition: all 0.3s ease-in;
    @media (min-width: 768px) {
      margin: 20px 30px;
    }
  }

  &__content {
    //margin: 50px auto 0;
    border-radius: 10px;
    display: block;
    width: 100%;
    //max-width: 1300px;
    margin: auto;
    transition: all 0.5s ease-in;
  }
}
