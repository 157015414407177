.notify-container {
  position: fixed;
  top: 40px;
  right: 40px;
  width: 300px;
  z-index: 9999;
}
@keyframes notification {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.notify-item {
  margin: 5px 10px;
  color: #FFF;
  border-radius: 5px;
  background: url('../../../src/assets/images/gritter.png') no-repeat left -40px;
  padding: 2px 11px 8px;
  font-family: verdana, serif;
  animation: notification 1s;
  max-width: 300px;

  &:hover {
    opacity: 0.8;
    box-shadow: 0 0 10px 0 rgb(15, 15, 15);
  }

  .notify-title {
    text-align: center;
    font-size: 14px;
    margin: 5px 0 0;
  }

  .notify-body {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 12px;

    &:before {
      width: 25px;
      height: 25px;
      margin: 15px;
      content: '';
      float: left;
      background: url('../../../src/assets/images/success.png') no-repeat;
      background-size: contain;
    }
  }

  &.error {
    background-color: rgba(203, 100, 94, 0.8);
    .notify-body {
      &:before {
        width: 25px;
        height: 25px;
        margin: 15px;
        content: '';
        float: left;
        background: url('../../../src/assets/images/error.png') no-repeat;
        background-size: contain;
      }
    }
  }

  .info {
    background-color: rgba(33, 150, 243, 0.8);
  }

  .notify-title {
    font-weight: 700;
  }
}
